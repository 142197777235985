import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { Display2 } from '@jsluna/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const Card = makeShortcode("Card");
const FbButton = makeShortcode("FbButton");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#what-is-an-error-message"
            }}>{`What is an error message?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-user-errors"
            }}>{`Writing user errors`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-system-errors"
            }}>{`Writing system errors`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "what-is-an-error-message",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-an-error-message",
        "aria-label": "what is an error message permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`What is an error message?`}</h2>
    <p>{`An error message alerts users after a problem has occurred. The message needs to tell users what's happened and what they need to do to continue their journey.`}</p>
    <p>{`As there are many different types of error messages, we've categorised errors in two ways.`}</p>
    <h3>{`User errors`}</h3>
    <p>{`These are mistakes or problems generated by the user, such as an incorrect password or a lack of internet connection.`}</p>
    <h3>{`System errors`}</h3>
    <p>{`These are problems generated by the system, such as a service outage or a declined order.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "writing-user-errors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-user-errors",
        "aria-label": "writing user errors permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing user errors`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#explain-what-happened">
        Explain what happened
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#give-a-next-step">
        Give a next step
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-a-descriptive-header">
        Use a descriptive header
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#dont-use-negative-language">
        Don't use negative language
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#dont-use-jargon">
        Don't use jargon
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="explain-what-happened">Explain what happened</h3>
    <p>{`Tell the user what’s gone wrong and why it went wrong. This can help the user avoid getting the same error in the future`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Passwords must have at least 8 characters. Please try again.</Do>
  <Dont mdxType="Dont">Invalid password. Please try again.</Dont>
    </Guideline>
    <p>{`If a user leaves a required field blank, explain why we need that information.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Add an email so we can get in touch if we need to</Do>
  <Dont mdxType="Dont">Email address is required</Dont>
    </Guideline>
    <h3 id="give-a-next-step">Give a next step</h3>
    <p>{`After you explain the issue to the user, give clear steps to resolve the problem.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Card className="ln-u-border" mdxType="Card">
      <p>
        You need to update the app to continue. Download the latest version to
        keep ordering.
      </p>
      <FbButton variant='primary' isRounded mdxType="FbButton">Download</FbButton>
    </Card>
  </Do>
  <Dont mdxType="Dont">
    <Card className="ln-u-border" mdxType="Card">
      <p>Error! This app is out of date.</p>
    </Card>
  </Dont>
    </Guideline>
    <h3 id="use-a-descriptive-header">Use a descriptive header</h3>
    <p>{`If your error message has a header, make the header copy as descriptive as possible.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <strong>Couldn't connect</strong>Check the internet connection and try
    again.
  </Do>
  <Dont mdxType="Dont">
    <strong>Something went wrong</strong>Network error 500. The operation
    couldn’t be completed.
  </Dont>
    </Guideline>
    <h3 id="dont-use-negative-language">Don't use negative language</h3>
    <p>{`Don’t use negative language in your message. This can make the user feel guilty for the error.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">That password doesn’t look right. Please try again.</Do>
  <Dont mdxType="Dont">Submission failed! Your password is invalid.</Dont>
    </Guideline>
    <p>{`Even if the error is caused by the user making a mistake, don’t address the user as ‘you’ in the message. This can blame the user for the error.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Phone numbers should contain only numbers</Do>
  <Dont mdxType="Dont">You added some invalid characters</Dont>
    </Guideline>
    <p>{`While it’s important to avoid negative language, be careful to avoid using exclamation marks or playful language.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Please enter your card number</Do>
  <Dont mdxType="Dont">Uh oh! Looks like you’ve forgotten your card number!</Dont>
    </Guideline>
    <h3 id="dont-use-jargon">Don't use jargon</h3>
    <p>{`Avoid using error numbers or technical language in your message. Try to use simple words wherever possible.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">We can’t find that postcode. Try again?</Do>
  <Dont mdxType="Dont">Bad request 500. Postcode supplied is invalid.</Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "writing-system-errors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-system-errors",
        "aria-label": "writing system errors permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing system errors`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#apologise-to-the-user">
        Apologise to the user
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#explain-what-happened-1">
        Explain what happened
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#give-a-next-step-1">
        Give a next step
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-a-descriptive-header-1">
        Use a descriptive header
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#dont-use-negative-language-1">
        Don't use negative language
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#dont-use-jargon-1">
        Don't use jargon
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="apologise-to-the-user">Apologise to the user</h3>
    <p>{`If the system error has inconvenienced the user in any way, start your message by saying ‘sorry’.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    Sorry, we have limited delivery slots right now. Please keep checking back.
  </Do>
  <Dont mdxType="Dont">We have limited delivery slots right now.</Dont>
    </Guideline>
    <h3 id="explain-what-happened-1">Explain what happened</h3>
    <p>{`Tell the user what’s gone wrong and why it went wrong. This can help the user avoid getting the same error in the future.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Passwords must have at least 8 characters. Please try again.</Do>
  <Dont mdxType="Dont">Invalid password. Please try again.</Dont>
    </Guideline>
    <p>{`If there’s no solution to the problem right now, tell the user what we’re doing to fix it.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    Sorry, we’re experiencing some difficulties with our service right now.
    We’re trying to fix the problem as soon as possible.
  </Do>
  <Dont mdxType="Dont">We’re experiencing some difficulties with our service right now.</Dont>
    </Guideline>
    <p>{`Don’t ask users to trust that we’re fixing the problem.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Sorry, we can’t accept gift cards right now. Please check back soon.</Do>
  <Dont mdxType="Dont">
    Sorry, we can’t accept gift cards right now. But don’t worry, it’s only
    temporary.
  </Dont>
    </Guideline>
    <h3 id="give-a-next-step-1">Give a next step</h3>
    <p>{`After you explain the issue to the user, give clear steps to resolve the problem.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Card className="ln-u-border" mdxType="Card">
      <p>
        You need to update the app to continue. Download the latest version to
        keep ordering.
      </p>
      <FbButton variant='primary' isRounded mdxType="FbButton">Download</FbButton>
    </Card>
  </Do>
  <Dont mdxType="Dont">
    <Card className="ln-u-border" mdxType="Card">
      <p>Error! This app is out of date.</p>
    </Card>
  </Dont>
    </Guideline>
    <h3 id="#use-a-descriptive-header-1">Use a descriptive header</h3>
    <p>{`If your error message has a header, make the header copy as descriptive as possible.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <strong>Couldn't connect</strong>Check the internet connection and try
    again.
  </Do>
  <Dont mdxType="Dont">
    <strong>Something went wrong</strong>Network error 500. The operation
    couldn’t be completed.
  </Dont>
    </Guideline>
    <h3 id="dont-use-negative-language-1">Don't use negative language</h3>
    <p>{`Don’t use negative language in your message. This can make the user feel guilty for the error.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">That password doesn’t look right. Please try again.</Do>
  <Dont mdxType="Dont">Submission failed! Your password is invalid.</Dont>
    </Guideline>
    <p>{`While it’s important to avoid negative language, be careful to avoid using exclamation marks or playful language.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Please enter your card number</Do>
  <Dont mdxType="Dont">Uh oh! Looks like you’ve forgotten your card number!</Dont>
    </Guideline>
    <h3 id="dont-use-jargon-1">Don't use jargon</h3>
    <p>{`Avoid using error numbers or technical language in your message. Try to use simple words wherever possible.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">We can’t find that postcode. Try again?</Do>
  <Dont mdxType="Dont">Bad request 500. Postcode supplied is invalid.</Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help you answer any questions around copy or help with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      